import React from 'react'
import "./styles.css"
const Footer = () => {
return <div className='footer'>
  
    <p className='footer-text'>Copyright: Tipsy Swing 2024</p>
    <a className="fa fa-facebook" title="facebook" href="https://www.facebook.com/p/Tipsy-Swing-61551893256317/"></a>

</div>
}
export default Footer