import React from "react";
import Logo from "../../images/logo_transparent.png";
import "./styles.css";
function Home() {
  return (
    <div className="homepage">
      <div className="logo">
        <img className="logo-img" src={Logo} alt="logo" />
      </div>
      <div className="homepage-text">
        <h1 className="head">From Cool to Hot....</h1>
        <h2 className="subhead">... And all Stops between</h2>
        <h3 className="strap">Acoustic jazz for every occasion</h3>
        <h4 className="contact">Contact Tim on 07702 242000</h4>
        <a className="email" href="mailto:tim@tipsyswing.com">
          <h4> email: tim@tipsyswing</h4>
        </a>
        <h4 className="contact">See us on Facebook <a className="fa fa-facebook" title="facebook" href="https://www.facebook.com/p/Tipsy-Swing-61551893256317/"></a></h4>
      </div>
    </div>
  );
}

export default Home;
